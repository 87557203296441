import { useNetInfo } from '@react-native-community/netinfo';
import {
  BlocklistsModel,
  CompanyType,
  CustomFieldsModel,
  CustomFieldType,
  IpadLang,
  Lang,
  PrimaryKeyType,
  VisitAuthorizationStatusType,
  VisitCheckType,
  VisitsModel,
} from '@w3lcome/types';
import { colors } from '_/config/theme';
import { getBase64String } from '_/helpers/getBase64String';
import getCountryByLang from '_/helpers/getCountryByLang';
import { CheckinInterface } from '_/interfaces/CheckinInterface';
import { CustomFieldWithError } from '_/interfaces/CustomFieldWithError';
import { VisitCheckinErrors } from '_/interfaces/VisitCheckinErrors';
import { VisitsModelCheckin } from '_/interfaces/VisitsModelCheckin';
import { ipadLogsApi, visitsApi } from '_/services/api';
import logger from '_/services/logger';
import VisitDB from '_/services/sqlite/VisitDB';
import { cpf } from 'cpf-cnpj-validator';
import { validate as validateEmail } from 'email-validator';
import bigInt from 'big-integer';
import {
  AsYouType,
  CountryCode,
  isValidPhoneNumber,
  NationalNumber,
  parsePhoneNumber,
} from 'libphonenumber-js';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import uuid from 'react-native-uuid';

import { countries } from '../helpers/countryDials';
import { useAuth } from './AuthContext';
import { useObjectState } from './useObjectState';
import { useChildrenCompanies } from './ChildrenCompaniesContex';
import { useCentralizedValuesFallback } from './useCentralizedValuesFallback';
import getVisitIdByQRCode from '_/helpers/getVisitIdByQRCode';
import { Platform } from 'react-native';
import { decodeQRCode } from '_/helpers/totp';
import { AppRoute } from '_/navigation/types';

interface VisitContextData {
  visit: Partial<VisitsModelCheckin>;
  loading: boolean;
  blockedListed: BlocklistsModel | undefined;
  setLoading: (loading: boolean) => void;
  setVisit: (values: Partial<VisitsModelCheckin>) => void;
  resetData: () => void;
  checkFields: (fields: CustomFieldWithError[], isHostForm?: boolean) => CustomFieldWithError[];
  getVisitorByPrimaryKey: () => Promise<Partial<VisitsModelCheckin> | undefined>;
  getVisitDataByField: (field?: CustomFieldsModel) => string | boolean;
  getVisitDataByFeedback: (feedback?: CustomFieldsModel) => string;
  isValidVisitDataByField: (field?: CustomFieldsModel) => boolean | undefined;
  setVisitDataByField: (value: string, field?: CustomFieldsModel) => void;
  setVisitDataByFeedback: (value: string, feedback?: CustomFieldsModel) => void;
  checkinVisit: (visit: Partial<VisitsModelCheckin>) => Promise<VisitsModel>;
  setVisitParser: (visit: VisitsModel) => void;
  setCountryPickerModalActive: (value: boolean) => void;
  countryPickerModalActive: boolean;
  setIsPrintVisitBadge: (value: boolean) => void;
  isPrintVisitBadge: boolean;
  showMessageErrorUniqueVisit: boolean;
  canceledVisits: null | VisitsModel[];
  setVisitDataByHostFields: (value: string, hostField?: CustomFieldsModel) => void;
  getVisitDataByHostFields: (hostField?: CustomFieldsModel) => string;
  getVisitByQRCode: (value: string) => Promise<VisitsModel>;
  navigateToHome: (navigate: (screen: AppRoute.HOME) => void) => void;
  setDefaultCountryCode: () => void;
}

const VisitContext = createContext<VisitContextData>({} as VisitContextData);

type VisitType = {
  children: React.ReactNode;
};

export const VisitProvider: React.FC<VisitType> = ({ children }) => {
  const { ipad } = useAuth();
  const { selectedOrFallbackCustomization, selectedOrFallbackNDA, selectedOrFallbackCompany } =
    useCentralizedValuesFallback();
  const [blockedListed, setBlockedListed] = useState<BlocklistsModel>();
  const [visit, setVisit, resetVisit] = useObjectState<Partial<VisitsModelCheckin>>({});
  const [loading, setLoading] = useState(false);
  const { i18n, t } = useTranslation(
    `translation_${selectedOrFallbackCustomization?.translationId}`
  );
  const [countryPickerModalActive, setCountryPickerModalActive] = useState(false);
  const [isPrintVisitBadge, setIsPrintVisitBadge] = useState(true);
  const [showMessageErrorUniqueVisit, setShowMessageErrorUniqueVisit] = useState(false);
  const [canceledVisits, setCanceledVisits] = useState<null | VisitsModel[]>(null);

  const { selectedCompanyId, removeRemoteData } = useChildrenCompanies();

  const netInfo = useNetInfo();

  const defaultCountryCode: CountryCode | undefined = useMemo(() => {
    const country = countries.find(
      (country) =>
        country.name.toLocaleLowerCase() === selectedOrFallbackCompany?.country?.toLowerCase()
    );

    return (country?.code as CountryCode) || getCountryByLang(i18n.language as IpadLang);
  }, [selectedOrFallbackCompany]);

  function getVisitorPrimaryKey() {
    if (!selectedOrFallbackCustomization?.primaryKey) {
      return null;
    }

    if (
      visit.isForeigner &&
      (selectedOrFallbackCustomization.primaryKey === PrimaryKeyType.CPF ||
        selectedOrFallbackCustomization.primaryKey === PrimaryKeyType.RG)
    ) {
      return visit.document;
    }

    switch (selectedOrFallbackCustomization.primaryKey) {
      case PrimaryKeyType.CPF:
        return visit.cpf;
      case PrimaryKeyType.DOCUMENT:
        return visit.document;
      case PrimaryKeyType.EMAIL:
        return visit.email;
      case PrimaryKeyType.PHONE:
        return parsePhoneNumber(visit.phone || '', visit.countryCode)?.number?.replace('+', '');
      case PrimaryKeyType.RG:
        return visit.rg;
    }
  }

  function setVisitParser(visit: VisitsModel) {
    let countryCode: CountryCode | undefined = defaultCountryCode;
    let phoneNumber: NationalNumber | undefined;

    if (visit.phone) {
      const { country, nationalNumber } = parsePhoneNumber(`+${visit.phone}`);
      countryCode = country;
      phoneNumber = nationalNumber;
    }

    const visitFormatted = {
      ...visit,
      phone: (phoneNumber as string) ?? null,
      countryCode,
    };

    setVisit(visitFormatted);
    return visitFormatted;
  }

  async function getVisitorByPrimaryKey() {
    const visitPrimaryKey = getVisitorPrimaryKey();
    const lowerCasedPrimaryKeyType = selectedOrFallbackCustomization?.primaryKey.toLowerCase();

    if (!visitPrimaryKey || !lowerCasedPrimaryKeyType) {
      return;
    }

    try {
      setLoading(true);

      const { visit: visitFound, canceledVisits } = await (selectedCompanyId
        ? visitsApi.getChildrenVisitByPrimaryKey(visitPrimaryKey, selectedCompanyId)
        : visitsApi.getVisitByPrimaryKey(visitPrimaryKey));

      if (canceledVisits) {
        setCanceledVisits(canceledVisits);
      }

      if (visitFound) {
        const visitFormatted = setVisitParser(visitFound);
        setLoading(false);
        return visitFormatted;
      }

      setLoading(false);

      return visit;
    } catch (error: any) {
      logger(error);
      setLoading(false);

      handleError(error, visitPrimaryKey);
    }
  }

  const handleError = (error: any, visitPrimaryKey: string) => {
    const errorCode = error?.response?.data?.data?.errorCode as VisitCheckinErrors;

    const errorActions: Record<VisitCheckinErrors, () => void> = {
      [VisitCheckinErrors.blockedList]: () => {
        showMessage({
          message: t('error'),
          description: t('checkinScreen.blockedList'),
          backgroundColor: colors.danger,
        });
      },
      [VisitCheckinErrors.uniqueVisit]: () => {
        setShowMessageErrorUniqueVisit(true);
        setVisitParser(error?.response?.data?.data?.visit?.[0]);
        ipadLogsApi.insert({
          companyId: ipad?.companyId,
          ipadId: ipad?.id,
          type: 'Unique Visit Error',
          message: `VISITOR: ${visitPrimaryKey} - ERROR: ${t('checkinScreen.uniqueVisit')}`,
        });
      },
      [VisitCheckinErrors.allowList]: () => {
        showMessage({
          message: t('error'),
          description: t('checkinScreen.allowList'),
          backgroundColor: colors.danger,
        });
      },
    };

    if (errorActions[errorCode]) {
      errorActions[errorCode]();
      throw error;
    }
  };

  const formatValue = (field: CustomFieldsModel, value: string) => {
    const numberOfCharacters = selectedOrFallbackCustomization?.numberOfCharacters || 0;

    if (
      field.type === CustomFieldType.IDENTITY_CARD &&
      numberOfCharacters > 0 &&
      value.length > numberOfCharacters
    ) {
      return value.substring(value.length - numberOfCharacters);
    }

    return value;
  };

  function getVisitDataByField(field?: CustomFieldsModel) {
    if (!field) {
      return '';
    }

    if (field.type === CustomFieldType.PHONE && visit?.phone) {
      if (visit?.phone.charAt(visit?.phone.length - 1) === ')') {
        return visit?.phone.replace(')', '');
      } else {
        const formattedNumber = new AsYouType(visit?.countryCode).input(visit.phone);
        return formattedNumber;
      }
    }

    if (field.type === CustomFieldType.IDENTITY_CARD) {
      return formatValue(field, visit.identity_card || '');
    }

    if (!field.customType) {
      const type = field.type.toLowerCase() as keyof VisitsModel;
      const data = visit[type] || '';

      if (field.type === CustomFieldType.CPF) {
        return cpf.format(data as string);
      }

      return data as string;
    }

    if (field.type === CustomFieldType.BOOLEAN) {
      const booleanField = visit.customFields?.find((cf) => cf.customFieldId === field.id);
      return booleanField?.value === 'true';
    }

    return visit.customFields?.find((cf) => cf.customFieldId === field.id)?.value || '';
  }

  function setVisitDataByField(value: string, field?: CustomFieldsModel) {
    if (!field) {
      return;
    }

    if (!field.customType) {
      const type = field.type.toLowerCase() as keyof VisitsModel;
      setVisit({ [type]: formatValue(field, value) });
    } else {
      const currentCustomField = visit.customFields?.find((cf) => cf.customFieldId === field.id);
      if (currentCustomField) {
        setVisit({
          customFields: visit.customFields?.map((cf) =>
            cf.customFieldId === field.id ? { ...cf, value } : cf
          ),
        });
      } else {
        const customField: any = { customFieldId: field.id, value };
        setVisit({ customFields: (visit.customFields || []).concat([customField]) });
      }
    }
  }

  function setVisitDataByFeedback(value: string, feedback?: CustomFieldsModel) {
    if (!feedback) {
      return;
    }

    if (!feedback.customType) {
      const type = feedback.type.toLowerCase() as keyof VisitsModel;
      setVisit({ [type]: value });
    } else {
      const currentFeedback = visit.feedbacks?.find((cf) => cf.customFieldId === feedback.id);
      if (currentFeedback) {
        setVisit({
          feedbacks: visit.feedbacks?.map((cf) =>
            cf.customFieldId === feedback.id ? { ...cf, value } : cf
          ),
        });
      } else {
        const feedbacks: any = { customFieldId: feedback.id, value };
        setVisit({ feedbacks: (visit.feedbacks || []).concat([feedbacks]) });
      }
    }
  }

  function getVisitDataByFeedback(feedback?: CustomFieldsModel) {
    if (!feedback) {
      return '';
    }

    if (!feedback.customType) {
      const type = feedback.type.toLowerCase() as keyof VisitsModel;
      const data = visit[type] || '';
      return data as string;
    }

    return visit.feedbacks?.find((cf) => cf.customFieldId === feedback.id)?.value || '';
  }

  function setVisitDataByHostFields(value: string, hostField?: CustomFieldsModel) {
    if (!hostField) {
      return;
    }

    const currentHostField = visit.extraHostFormFields?.find(
      (cf) => cf.customFieldId === hostField.id
    );

    if (currentHostField) {
      setVisit({
        extraHostFormFields: visit.extraHostFormFields?.map((cf) =>
          cf.customFieldId === hostField.id ? { ...cf, value } : cf
        ),
      });
    } else {
      const extraHostFormFields: any = { customFieldId: hostField.id, value };
      setVisit({
        extraHostFormFields: (visit.extraHostFormFields || []).concat([extraHostFormFields]),
      });
    }
  }

  function getVisitDataByHostFields(hostField?: CustomFieldsModel) {
    if (!hostField) {
      return '';
    }

    return visit.extraHostFormFields?.find((cf) => cf.customFieldId === hostField.id)?.value || '';
  }

  function isNameValid(name: string) {
    if (name.length < 3) {
      return false;
    }

    if (/[^\u0000-\u00ff]/.test(name)) {
      return true;
    }

    const isFullName = new RegExp(
      `^[a-zA-Zà-úÀ-Ú]([-']?[a-zA-Zà-úÀ-Ú]+)*( [a-zA-Zà-úÀ-Ú]([-']?[a-zA-Zà-úÀ-Ú]+)*)+$`
    );

    return selectedOrFallbackCustomization?.fullNameRequired ? isFullName.test(name.trim()) : true;
  }

  function isValidVisitDataByField(field?: CustomFieldsModel) {
    if (!field) {
      return false;
    }

    if (
      visit.isForeigner &&
      (field.type === CustomFieldType.CPF || field.type === CustomFieldType.RG)
    ) {
      return true;
    }

    const fieldIsNotRequired = !field.required || field.disabled;
    const data = getVisitDataByField(field);

    if (!data) {
      return fieldIsNotRequired;
    }

    switch (field.type) {
      case CustomFieldType.CPF:
        return cpf.isValid(data as string);
      case CustomFieldType.NAME:
        return isNameValid(data as string);
      case CustomFieldType.EMAIL:
        return validateEmail(data as string);
      case CustomFieldType.PHONE:
        return isValidPhoneNumber(data as string, visit.countryCode);
      default:
        return !!data;
    }
  }

  function isValidExtraHostFormDataByField(field?: CustomFieldsModel) {
    if (!field) {
      return false;
    }

    const fieldIsNotRequired = !field.required || field.disabled;
    const data = getVisitDataByHostFields(field);

    if (!data) {
      return fieldIsNotRequired;
    }

    return !!data;
  }

  function checkFields(fields: CustomFieldWithError[], isHostForm = false): CustomFieldWithError[] {
    return fields.map((field) => {
      return {
        ...field,
        hasError: isHostForm
          ? !isValidExtraHostFormDataByField(field)
          : !isValidVisitDataByField(field),
      };
    });
  }

  function navigateToHome(navigate: (screen: AppRoute.HOME) => void) {
    resetData();
    if (selectedOrFallbackCompany?.type !== CompanyType.INDEPENDENT) removeRemoteData();
    navigate(AppRoute.HOME);
  }

  function resetData() {
    resetVisit({
      id: uuid.v4().toString(),
      checkinType: VisitCheckType.IPAD,
      checkinByIpadId: ipad?.id,
      countryCode: defaultCountryCode,
    });
    setCountryPickerModalActive(false);
    setIsPrintVisitBadge(true);
    setShowMessageErrorUniqueVisit(false);
    setBlockedListed(undefined);
    setLoading(false);
    setCanceledVisits(null);
  }

  async function checkinVisit(visit: Partial<VisitsModelCheckin>) {
    const customFields = visit?.customFields?.filter((field) => field.customFieldId);

    const parsedVisit: Partial<VisitsModelCheckin> = {
      ...visit,
      customFields,
      finishCheckinAt: new Date(),
      checkinAt: new Date(),
      picture: null,
      signedNda: undefined,
      lang: i18n.language as Lang,
      phone: visit.phone
        ? (parsePhoneNumber(visit.phone, visit.countryCode).number as string)?.replace('+', '')
        : undefined,
      checkoutAt: undefined,
      ...(selectedOrFallbackCustomization?.authorizationEnabled &&
        !visit.meeting && { authorizationStatus: VisitAuthorizationStatusType.AWAITING }),
      checkinByIpadId: ipad?.id,
    };

    const value: CheckinInterface = {
      visit: parsedVisit,
      visitNda: visit.signedNda,
      visitPicture: getBase64String(visit.picture?.base64),
      visitNdaText: selectedOrFallbackNDA?.ndaText,
    };

    try {
      // if (!netInfo.isConnected) {
      //   VisitDB.create(value);
      //   return;
      // }

      return selectedCompanyId
        ? await visitsApi.childrenCheckin(value, {
            params: { companyId: selectedCompanyId },
          })
        : await visitsApi.checkin(value);
    } catch (error: any) {
      logger(`[checkinVisit] error: ${error}`);

      ipadLogsApi.insert({
        companyId: ipad?.companyId,
        ipadId: ipad?.id,
        type: 'Checkin Error',
        message: `VISITOR: ${JSON.stringify({ ...parsedVisit, signedNda: null, picture: null })}
        -
        STATUS: ${error?.response?.status}
        _
        ERROR: ${error?.response?.data?.message || 'Unknow error'}`,
      });

      if (error?.response?.status >= 500) {
        showMessage({
          message: t('error'),
          description: t('serverErrorMessage'),
          backgroundColor: colors.danger,
        });
      } else if (Platform.OS !== 'web') {
        // VisitDB.create(value);
      }
      throw error;
    }
  }

  async function getVisitByQRCode(data: string) {
    try {
      const visitId = getVisitIdByQRCode(data);

      if (visitId) {
        return selectedCompanyId
          ? await visitsApi.getChildrenVisit(visitId)
          : await visitsApi.getItem(visitId);
      }
      const isNumber = new RegExp('^[0-9]*$');

      if (isNumber.test(data)) {
        const binaryValue = bigInt(data).toString(2);
        const cardNumber = binaryValue.length > 40 ? decodeQRCode(data) : data;
        const params = {
          cardNumber: cardNumber,
          '$sort[createdAt]': -1,
        };

        const result = selectedCompanyId
          ? await visitsApi.getChildrenVisits({ params })
          : await visitsApi.getList(params);

        return result.data[0];
      }
    } catch (error: any) {
      if (error.response?.data?.message === VisitCheckinErrors.blockedList) {
        return showMessage({
          message: t('error'),
          description: t('checkinScreen.blockedList'),
          backgroundColor: colors.danger,
        });
      }

      logger(`[getVisitByQRCode] error: ${error}`);
      throw error;
    }
  }

  function setDefaultCountryCode() {
    setVisit({ countryCode: defaultCountryCode });
  }

  return (
    <VisitContext.Provider
      value={{
        visit,
        loading,
        blockedListed,
        setLoading,
        setVisit,
        resetData,
        checkFields,
        getVisitorByPrimaryKey,
        isValidVisitDataByField,
        getVisitDataByField,
        setVisitDataByField,
        setVisitDataByFeedback,
        getVisitDataByFeedback,
        checkinVisit,
        setVisitParser,
        setCountryPickerModalActive,
        countryPickerModalActive,
        setIsPrintVisitBadge,
        isPrintVisitBadge,
        showMessageErrorUniqueVisit,
        canceledVisits,
        setVisitDataByHostFields,
        getVisitDataByHostFields,
        getVisitByQRCode,
        navigateToHome,
        setDefaultCountryCode,
      }}
    >
      {children}
    </VisitContext.Provider>
  );
};

export function useVisit(): VisitContextData {
  const context = useContext(VisitContext);

  if (!context) {
    throw new Error('useVisit must be used within an VisitProvider');
  }

  return context;
}
