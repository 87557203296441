import { useNetInfo } from '@react-native-community/netinfo';
import { CompanyType, VisitsModel } from '@w3lcome/types';
import { visitsApi } from '_/services/api';
import logger from '_/services/logger';
import VisitDB from '_/services/sqlite/VisitDB';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { useAuth } from './AuthContext';
import { useCustomization } from './CustomizationContext';
import { useChildrenCompanies } from './ChildrenCompaniesContex';

interface VisitsListContextData {
  visitsList: VisitsModel[];
  searchVisit(text?: string): void;
}

const VisitsListContext = createContext<VisitsListContextData>({} as VisitsListContextData);

type VisitsListType = {
  children: React.ReactNode;
};

export const VisitsListProvider: React.FC<VisitsListType> = ({ children }) => {
  const { ipad } = useAuth();
  const [visitsList, setVisitsList] = useState<VisitsModel[]>([]);
  const [visitsListAll, setVisitsListAll] = useState<VisitsModel[]>([]);
  const { company } = useCustomization();
  const netInfo = useNetInfo();

  const { selectedCompanyId } = useChildrenCompanies();

  const getListVisit = async () => {
    try {
      if (company?.type === CompanyType.PARENT) {
        return;
      }

      const result = await visitsApi.getList(
        {
          checkoutAt: '<null>',
          'name[$ne]': '<null>',
          'checkinAt[$ne]': '<null>',
          '$sort[createdAt]': -1,
        },
        {
          paramsSerializer: {
            serialize: (params) => {
              return new URLSearchParams(params).toString();
            },
          },
        }
      );

      const { data } = result;

      setVisitsList(data);
      setVisitsListAll(data);
    } catch (error) {
      VisitDB.all().then((data: any) => {
        setVisitsList(data);
        setVisitsListAll(data);
      });
    }
  };

  function checkinVisitsSavedInSQlite() {
    VisitDB.all().then((data) => {
      if (data.length === 0) {
        return;
      }
      data.map((visit, index) => {
        setTimeout(async () => {
          try {
            await visitsApi.checkin(visit);
            VisitDB.deleteOne(visit.visit.id!);
          } catch (error) {
            logger(error);
          }
        }, 500 * index);
      });
    });
  }

  // useEffect(() => {
  //   if (!netInfo.isConnected) {
  //     return;
  //   }

  //   if (company) {
  //     checkinVisitsSavedInSQlite();
  //   }
  // }, [netInfo.isConnected, company]);

  useEffect(() => {
    if (!ipad?.id) {
      return;
    }

    getListVisit();
  }, [ipad?.id]);

  const searchVisit = async (text: string = '') => {
    if (text.length >= 3) {
      if (!netInfo.isConnected) {
        const filtered = visitsListAll.filter(
          ({ name }) => name && name.toLowerCase().includes(text.toLowerCase())
        );
        return setVisitsList(filtered);
      }

      const { data } =
        company?.type === CompanyType.PARENT
          ? await visitsApi.searchChildrenVisitToCheckout(text, {
              companyId: selectedCompanyId ?? company.id,
            })
          : await visitsApi.searchVisitToCheckout(text);
      return setVisitsList(data);
    }
  };

  return (
    <VisitsListContext.Provider value={{ visitsList, searchVisit }}>
      {children}
    </VisitsListContext.Provider>
  );
};

export function useVisitsList(): VisitsListContextData {
  const context = useContext(VisitsListContext);

  if (!context) {
    throw new Error('useVisits must be used within an VisitsProvider');
  }

  return context;
}
